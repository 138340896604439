import {navigate} from 'gatsby';
import {useEffect} from 'react';
import {Layout} from '../../components/layout';
import useChannelTabsData, {ChannelTabsVariant} from '../../hooks/useChannelTabsData';

const VideosPage = () => {
    const channelTabsData = useChannelTabsData(ChannelTabsVariant.videos);

    useEffect(() => {
        if (!channelTabsData || channelTabsData.length === 0) {
            return;
        }

        navigate(channelTabsData[0].to);
    }, [channelTabsData]);

    return (
        <Layout
            title="Video manager"
        />
    );
};

export default VideosPage;
